class UtilCrono {

	static WORKER: Worker;
	static LISTENERS: Function[] = [];
	static LISTENERS_BY_ID: Map<string, Function> = new Map();

	// sem id

	static adicionarListener(funcao: Function) {
		UtilCrono.LISTENERS.push(funcao);
		UtilCrono.atualizar();
	}

	static removerListener(funcao: Function) {
		UtilCrono.LISTENERS = UtilCrono.LISTENERS.filter(f => f != funcao);
		UtilCrono.atualizar();
	}	

	// com id

	static addListener(id: string, funcao: Function) {
		UtilCrono.LISTENERS.push(funcao);
		UtilCrono.LISTENERS_BY_ID.set(id, funcao);
		UtilCrono.atualizar();
	}

	static delListener(id: string) {
		const funcao = UtilCrono.LISTENERS_BY_ID.get(id);
		if (!funcao) return;
		UtilCrono.LISTENERS = UtilCrono.LISTENERS.filter(f => f != funcao);
		UtilCrono.atualizar();
	}

	static atualizar() {
		if (UtilCrono.LISTENERS.length === 0) {
			// parar
			if (UtilCrono.WORKER != null) {
				try {
					UtilCrono.WORKER.terminate();
				} catch (ignored) {}
				UtilCrono.WORKER = null;
				console.info("crono-worker parada.");
			}
		} else {
			// rodar
			if (UtilCrono.WORKER == null) {
				UtilCrono.WORKER = new Worker("crono-worker.js");
				UtilCrono.WORKER.addEventListener("message", (e) => {

					if (!e?.data) return;
		
					const msDecorridos = e.data;

					UtilCrono.LISTENERS.forEach(f => {
						try {
							f(msDecorridos);
						} catch (e) {
							console.error("Erro ao disparar tick para " + f, e);
						}
					});
				});
			}
		}
	}
}
