class UtilString {

	static ID_ELEMENTO_AUTO = 0;

	static escaparAspas(s: string) : string {
		return s.replace(/\'/g, "&lsquo;").replace(/\"/g, "&rdquo;");
	}

	static getSomenteNumeros(s: string) : string {
		if (!s) return null;
		return s.replace(/\D/g, "");
	}

	static substituirAcentuacao(s: string) : string {
		
		if (!s) return s;

		return s.replace(/[\xC0-\xC5]/g, "A")
	              .replace(/[\xC6]/g, "AE")
	              .replace(/[\xC7]/g, "C")
	              .replace(/[\xC8-\xCB]/g, "E")
	              .replace(/[\xCC-\xCF]/g, "I")
	              .replace(/[\xD0]/g, "D")
	              .replace(/[\xD1]/g, "N")
	              .replace(/[\xD2-\xD6\xD8]/g, "O")
	              .replace(/[\xD9-\xDC]/g, "U")
	              .replace(/[\xDD]/g, "Y")
	              .replace(/[\xDE]/g, "P")
	              .replace(/[\xE0-\xE5]/g, "a")
	              .replace(/[\xE6]/g, "ae")
	              .replace(/[\xE7]/g, "c")
	              .replace(/[\xE8-\xEB]/g, "e")
	              .replace(/[\xEC-\xEF]/g, "i")
	              .replace(/[\xF1]/g, "n")
	              .replace(/[\xF2-\xF6\xF8]/g, "o")
	              .replace(/[\xF9-\xFC]/g, "u")
	              .replace(/[\xFE]/g, "p")
	              .replace(/[\xFD\xFF]/g, "y")
	              ;
	}

	static inserir(s1: string, index: number, s2: string) : string {
		if (index > 0) {
			return s1.substring(0, index) + s2 + s1.substring(index);
		} else {
			return s2 + s1;
		}
	}

	static max(...strs): string {
		if (!strs || strs.length == 0) return null;
		return strs.filter(s => s != null).sort().pop();
	}

	static trimLinhas(txt: string) {
		if (!txt) return txt;
		return txt.split("\n").map(linha => linha.trim()).join("\n");
	}

	static converterArrayEmTexto(array) {
		if (!array || array.length === 0) {
			return "";
		} else if (array.length === 1) {
			return array[0].toString();
		} else if (array.length === 2) {
			return array.join(" e ");
		} else {
			const lastItem = array.pop();
			return array.join(", ") + " e " + lastItem;
		}
	}
	
	static snakeToCamelCase(snake: string): string {
		const camel: string[] = [];
		for (const parte of snake.toLowerCase().split("_")) {
			if (camel.length === 0) {
				camel.push(parte);
			} else {
				camel.push(parte.substring(0, 1).toUpperCase());
				camel.push(parte.substring(1));
			}
		}
		return camel.join("");
	}

	static camelToSnakeCase(camel: string): string {
		return camel.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
	}

	static isEmpty(obj: any) {
		if (obj === undefined || obj === null) return true;
		if (Array.isArray(obj)) return obj.length === 0;
		const tipo = typeof obj;
		if (tipo === "string") return obj.trim().length === 0;
		if (tipo === "number") return Number.isNaN(obj);
		return false;
	}

	static hasValue(obj: any) {
		if (obj === undefined || obj === null) return false;
		if (Array.isArray(obj)) return obj.length > 0;
		const tipo = typeof obj;
		if (tipo === "string") return obj.trim().length > 0;
		if (tipo === "number") return !Number.isNaN(obj);
		return true;
	}
	
	static gerarId() {
		return "_fpid" + UtilString.ID_ELEMENTO_AUTO++;
	}

	static capitalizarPrimeiraLetra(s: string) {
		if (!s) return s;
		return s.charAt(0).toUpperCase() + s.slice(1);
	}
}
