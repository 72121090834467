class UtilArquivo {
	
	static EXTENSOES_AUDIO = [".mp3", ".wma", ".wav", ".m4a", ".aac"];
	static EXTENSOES_VIDEO = [".webm", ".mp4", ".mov", ".wmv", ".avi", ".flv"];
	static EXTENSOES_IMAGEM = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
	static EXTENSOES_PLANILHA = [".xlsx", ".xls", ".xlsm", ".xlsb", ".odf"];

	static removerUrlParams(nome){
		if (nome.includes("?")) {
			nome = nome.split("?")[0];
		}

		return nome;
	}
	
	static getNomeArquivo(s) {
		if (s == null) return "";
		let nome = s.substring(s.lastIndexOf('/') + 1);

		nome = UtilArquivo.removerUrlParams(nome);

		return decodeURI(nome);
	}

	static getNomeArquivoSemHifens(s) {
		if (s == null) return "";
		let nome = s.substring(s.lastIndexOf('/') + 1);

		nome = UtilArquivo.removerUrlParams(nome);

		return nome.replace(/\-/g, ' ');
	}
	
	static getNomeArquivoSemExtensaoEHifens(s) {
		if (s == null) return "";
		return s.substring(s.lastIndexOf('/') + 1, s.lastIndexOf('.')).replace(/\-/g, ' ');
	}
	
	static getNomeArquivoSemExtensao(s) {
		if (s == null) return "";
		let nome = s;

		nome = UtilArquivo.removerUrlParams(nome);

		return nome.substring(s.lastIndexOf('/') + 1, s.lastIndexOf('.'))
	}

	static isComExtensao(url: string, extensoes: string[]): boolean {

		if (url == null) return false;

		const nome = UtilArquivo.removerUrlParams(url);

		return extensoes.some(extensao => nome.endsWith(extensao));
	}
	
	static getExtensao(nomeArquivo: string) {
		
		if (nomeArquivo == null) return null;
		
		nomeArquivo = UtilArquivo.removerUrlParams(nomeArquivo);
		
		const indiceUltimoPonto = nomeArquivo.lastIndexOf('.');
		
		if (indiceUltimoPonto == -1) {
			return "";
		} else {
			return nomeArquivo.substring(indiceUltimoPonto);
		}
	}

	static isAudio(path: string): boolean {
		const e = UtilArquivo.getExtensao(path);

		if (e == null) return false;
		
		return UtilArquivo.EXTENSOES_AUDIO.includes(e.toLowerCase());
	}
	
	static isImagem(path: string): boolean {
		const e = UtilArquivo.getExtensao(path);
		
		if (e == null) return false;
		
		return UtilArquivo.EXTENSOES_IMAGEM.includes(e.toLowerCase());
	}

	static isVideo(path: string): boolean {
		const e = UtilArquivo.getExtensao(path);
		
		if (e == null) return false;
		
		return UtilArquivo.EXTENSOES_VIDEO.includes(e.toLowerCase());
	}

	static isPlanilha(path: string): boolean {
		const e = UtilArquivo.getExtensao(path);
		
		if (e == null) return false;
		
		return UtilArquivo.EXTENSOES_PLANILHA.includes(e.toLowerCase());
	}
	
	static async toDataURL(file: File): Promise<string | ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}

	static async toFile(dataUrl: string, filename: any) {
		const res = await fetch(dataUrl);
		const buf = await res.arrayBuffer();
		return new File([buf], filename, {type: 'image/jpeg', lastModified: Date.now()});
	};
}

