class UtilPDF {

	/**
	 * Só faz página 1 por enquanto.
	 */
	static async toJpg(file: any): Promise<File> {

		if (file.type !== 'application/pdf') throw new Error("Arquivo não é PDF.");

		const { pdfjsLib }: any = await UtilBoot.carregarPDFExtractor();
		pdfjsLib.GlobalWorkerOptions.workerSrc = UtilBoot.getURLBaseFrontend() + 'widgets/pdf.worker.min.js';
		const worker = new pdfjsLib.PDFWorker();
		const dataUrl = await UtilArquivo.toDataURL(file);
		const pdf = await pdfjsLib.getDocument({url: dataUrl, worker}).promise;
		const nomeArquivoSemExtensao = UtilArquivo.getNomeArquivoSemExtensao(file.name);
		return await UtilPDF.convertPdfPageToJpg(pdf, 1, nomeArquivoSemExtensao);
	}

	private static async convertPdfPageToJpg(pdf: any, pageNumber: any, fileName: any) {
		const page = await pdf.getPage(pageNumber);
		const viewport = page.getViewport({ scale: 2 });
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const renderContext = { canvasContext: ctx, viewport: viewport };

		canvas.height = viewport.height;
		canvas.width = viewport.width;

		await page.render(renderContext).promise;

		const dataUrl = canvas.toDataURL();

		return UtilArquivo.toFile(dataUrl, `${fileName}-pag${pageNumber}.jpg`);
	}
}
